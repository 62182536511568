import { mergeMap as _observableMergeMap, catchError as _observableCatch } from 'rxjs/operators';
import { Observable, from as _observableFrom, throwError as _observableThrow, of as _observableOf } from 'rxjs';
import { Injectable, Inject, Optional, InjectionToken } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpResponseBase, HttpErrorResponse } from '@angular/common/http';
import * as moment from 'moment';
import { API_BASE_URL } from '@shared/service-proxies/service-proxies';

@Injectable()
export class CableproCalculationServiceProxy {
    private http: HttpClient;
    private baseUrl2: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor(@Inject(HttpClient) http: HttpClient, @Inject(API_BASE_URL) baseUrl2?: string) {
        this.http = http;
        this.baseUrl2 = baseUrl2 ? baseUrl2 : "";
    }

    CablePulling(obj: object) {
        let url_ = this.baseUrl2 + "/api/cablepullingtensionmain/calculate";

        let options_: any = {
            body: JSON.stringify(obj),
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_: any) => {
            return this.processCalculate(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processCalculate(<any>response_);
                } catch (e) {
                    return <Observable<any>>_observableThrow(e);
                }
            } else
                return <Observable<any>>_observableThrow(response_);
        }));
    }

    NetworkCalculation(obj: object) {
        let url_ = this.baseUrl2 + "/api/NetworkCalculation/calculate";

        let options_: any = {
            body: JSON.stringify(obj),
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_: any) => {
            return this.processCalculate(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processCalculate(<any>response_);
                } catch (e) {
                    return <Observable<any>>_observableThrow(e);
                }
            } else
                return <Observable<any>>_observableThrow(response_);
        }));
    }

    Calculate(obj: object) {
        let url_ = this.baseUrl2 + "/api/Calculation/Calculate";
         

        let options_: any = {
            body: JSON.stringify(obj),
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_: any) => {
            return this.processCalculate(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processCalculate(<any>response_);
                } catch (e) {
                    return <Observable<any>>_observableThrow(e);
                }
            } else
                return <Observable<any>>_observableThrow(response_);
        }));
    }

    setCalculationOutput(input: CalculationResult | null | undefined): Observable<any> {
        let url_ = this.baseUrl2 + "/api/services/app/Calculation/CreateCalculationOutput";
        //url_ += "userID=" + encodeURIComponent("" + userID) + "&";
        url_ = url_.replace(/[?&]$/, "");
        const content_ = JSON.stringify(input);

        let options_: any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_: any) => {
            return this.processCalculate(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processCalculate(<any>response_);
                } catch (e) {
                    return <Observable<any>><any>_observableThrow(e);
                }
            } else
                return <Observable<any>><any>_observableThrow(response_);
        }));
    }



    ProtectiveCalculate(obj: object) {
        let url_ = this.baseUrl2 + "/api/protectivedevice/calculate";

        let options_: any = {
            body: JSON.stringify(obj),
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
            // headers: new HttpHeaders({
            //     "Content-Type": "text/html; charset=UTF-8",
            //     "Accept": "text/html; charset=UTF-8"
            // })
        };

        return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_: any) => {
            return this.processCalculate(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processCalculate(<any>response_);
                } catch (e) {
                    return <Observable<any>>_observableThrow(e);
                }
            } else
                return <Observable<any>>_observableThrow(response_);
        }));
    }
    BSCalculate(obj: object) {
        let url_ = this.baseUrl2 + "/api/bscalculation/calculate";

        let options_: any = {
            body: JSON.stringify(obj),
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_: any) => {
            return this.processCalculate(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processCalculate(<any>response_);
                } catch (e) {
                    return <Observable<any>>_observableThrow(e);
                }
            } else
                return <Observable<any>>_observableThrow(response_);
        }));
    }

    ArcFlashCalculation(obj: object) {
        let url_ = this.baseUrl2 + "/api/arcflash/calculate";

        let options_: any = {
            body: JSON.stringify(obj),
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_: any) => {
            return this.processCalculate(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processCalculate(<any>response_);
                } catch (e) {
                    return <Observable<any>>_observableThrow(e);
                }
            } else
                return <Observable<any>>_observableThrow(response_);
        }));
    }

    UpdateXmlVersion(obj: object) {
        let url_ = this.baseUrl2 + "/api/UpdateXmlVersion/calculate";

        let options_: any = {
            body: JSON.stringify(obj),
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_: any) => {
            return this.processUpdateXmlVersion(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processUpdateXmlVersion(<any>response_);
                } catch (e) {
                    return <Observable<any>>_observableThrow(e);
                }
            } else
                return <Observable<any>>_observableThrow(response_);
        }));
    }
    protected processUpdateXmlVersion(response: HttpResponseBase) {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
                (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } };
        if (status === 200) {
            return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                let result200: any = null;
                let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result200 = resultData200;
                return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf(<any>null);
    }
    protected processCalculate(response: HttpResponseBase) {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
                (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } };
        if (status === 200) {
            return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                let result200: any = null;
                let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result200 = resultData200;
                return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf(<any>null);
    }
    PostCalculation(data: MaxDemandData) {
        let url_ = this.baseUrl2 + "api/maxdemand/calculation";

        let options_: any = {
            body: JSON.stringify(data),
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_: any) => {
            return this.processGetCalculation(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processGetCalculation(<any>response_);
                } catch (e) {
                    return <Observable<any>>_observableThrow(e);
                }
            } else
                return <Observable<any>>_observableThrow(response_);
        }));
    }
    PostAutoCalculation(data: MaxDemandData) {
        let url_ = this.baseUrl2 + "/api/AutoMaxDemand/Calculate";

        let options_: any = {
            body: JSON.stringify(data),
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_: any) => {
            return this.processGetCalculation(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processGetCalculation(<any>response_);
                } catch (e) {
                    return <Observable<any>>_observableThrow(e);
                }
            } else
                return <Observable<any>>_observableThrow(response_);
        }));
    }

    protected processGetCalculation(response: HttpResponseBase) {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
                (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } };
        if (status === 200) {
            return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                let result200: any = null;
                let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result200 = resultData200;
                return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf(<any>null);
    }
}
export class MaxDemandData {
    ElectricalInstallation: number;
    Node_Name: string;
    Node_Id: string;
    Node_Prefix: string;
    Voltage: number;
    BrowserName: string;
    UserName: string;
    CalculationId: number;
    Settings: SettingsData;
    Items: LoadItem[];
}
export class SettingsData {
    Standard: string;
    VoltageFactor: string;
    DcVoltage: string;
    OnePhaseVoltage: string;
    ThreePhaseVoltage: string;
}
export class LoadItem {
    id: number;
    description: string;
    loadGroup: number;
    subGroup: number;
    phases: number;
    rating: number;
    units: number;
    powerFactor: number;
    red: number;
    white: number;
    blue: number;
    diversity: number;
    factor: number;
    loadGroupId: number;
    subGroupId: number;
    loadName: string;
    subName: string;
    resultR: string;
    resultW: string;
    resultB: string;
}
export class SwaggerException extends Error {
    message: string;
    status: number;
    response: string;
    headers: { [key: string]: any; };
    result: any;

    constructor(message: string, status: number, response: string, headers: { [key: string]: any; }, result: any) {
        super();

        this.message = message;
        this.status = status;
        this.response = response;
        this.headers = headers;
        this.result = result;
    }

    protected isSwaggerException = true;

    static isSwaggerException(obj: any): obj is SwaggerException {
        return obj.isSwaggerException === true;
    }
}

export class CalculationResult {
    Id: number | undefined;
    resultValue: string | undefined;
}

function throwException(message: string, status: number, response: string, headers: { [key: string]: any; }, result?: any): Observable<any> {
    if (result !== null && result !== undefined)
        return _observableThrow(result);
    else
        return _observableThrow(new SwaggerException(message, status, response, headers, null));
}

function blobToText(blob: any): Observable<string> {
    return new Observable<string>((observer: any) => {
        if (!blob) {
            observer.next("");
            observer.complete();
        } else {
            let reader = new FileReader();
            reader.onload = function () {
                observer.next(this.result);
                observer.complete();
            }
            reader.readAsText(blob);
        }
    });
}

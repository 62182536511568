import { Component, Injector, OnInit, AfterViewInit } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { CheckOutSuccessOutput, CheckOutServiceProxy } from '@shared/service-proxies/service-proxies';
import { ActivatedRoute, Router } from '@angular/router';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';
@Component({
    selector: 'app-anonymoususer.checkout.success',
    templateUrl: './anonymoususer.checkout.success.component.html',
    animations: [appModuleAnimation()]
})

export class AnonymousUserCheckOutSuccessComponent extends AppComponentBase implements OnInit, AfterViewInit {
    public checkOutSuccessOutput: CheckOutSuccessOutput = new CheckOutSuccessOutput();
    public stripe: any;
    public checkoutSessionId: string;
    public isDisabled: boolean = true;
    constructor(injector: Injector, private router: Router, private _planService: CheckOutServiceProxy, private activeRoute: ActivatedRoute, private _authService: AppAuthService) {
        super(injector);
    }

    ngOnInit(): void {
        this.checkoutSessionId = this.activeRoute.snapshot.queryParamMap.get('session_id');
        this.isDisabled = true;
        this.CreateSubscription();
    }
    ngAfterViewInit() {
        $('#aContinueToHomepage').on('click', (e) => {
            this.appSession.init().then((result) => {
                this.router.navigateByUrl("/app/main/projects" + '?v=' + this.appSession.application.version);
            });
        });

    }
    CreateSubscription(): void {
        setTimeout(() => {
            this._planService.createSubscription(this.checkoutSessionId).subscribe(result => {
                if (result !== undefined && result !== null) {
                    this.checkOutSuccessOutput = result;
                    setTimeout(() => {
                        this.appSession.init().then((result) => {
                            this.router.navigateByUrl("/app/main/projects" + '?v=' + this.appSession.application.version);
                        });
                    }, 5000);
                }
            }, () => { }, () => { this.isDisabled = false; });
        }, 5000);
    }
}

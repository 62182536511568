import { Component, EventEmitter, Injectable, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalConfig, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.css']
})
@Injectable()
export class ConfirmationModalComponent implements OnInit {

  @ViewChild('confirmationModal') private modalContent!: TemplateRef<ConfirmationModalComponent>
  @Input() public isAlertOnly: boolean = false;
  @Input() public modalTitle: any;
  @Input() public modalBody: any;
  @Input() public acceptButtonName?: string = 'Ok';
  @Input() public acceptButtonClass?: any = 'btn-danger';

  @Input() cancelButtonName?: string = 'Cancel';
  @Input() cancelButtonClass?: any = 'btn-default';

  @Input() modalSize?: string = 'md'
  @Input() iconClass?: string;
  @Input() showCloseBtn: boolean = true;

  private modalRef!: NgbModalRef;

  constructor(config: NgbModalConfig, private modalService: NgbModal, public activeModal: NgbActiveModal) {
    config.backdrop = 'static';
    config.keyboard = false;
    config.animation = true;
    config.centered = true;
  }

  ngOnInit(): void {
  }

  // open(callback?: Function) {
  //   this.modalRef = this.modalService.open(this.modalContent, { size: this.modalSize })
  //   this.modalRef.result.then((result: boolean) => {
  //     if (callback)
  //       callback(result);
  //   }, (reason) => {
  //     if (callback)
  //       callback(reason);
  //   });
  // }

}

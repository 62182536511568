import { Injectable } from '@angular/core';
import { ChatWidget } from '@clickconnector/widget-sdk';
import { AppConsts } from '@shared/AppConsts';

@Injectable()
export class AppAuthService {

    logout(reload?: boolean, returnUrl?: string): void {
        abp.auth.clearToken();
        this.clearUsetifulData();
        this.clearClickConnector();
        if (reload !== false) {
            if (returnUrl) {
                location.href = returnUrl;
            } else {
                location.href = AppConsts.appBaseUrl;
            }
        }
    }


    clearUsetifulData() {
        if ((window as any).USETIFUL && (window as any).USETIFUL.user) {
            (window as any).USETIFUL.user.setId(null); // Remove userId
            (window as any).USETIFUL.user.clearProgress(); // Remove progress of tools
            // window.USETIFUL.checklist.done([]); // Remove progress of checklist            
        }

        // if ((window as any).USETIFUL) {
        //     (window as any).USETIFUL.reinitialize(); // Reinitialize the product tour
        // }

        // clear local storage
        localStorage.removeItem('tours'); // Replace 'keyName' with the name of the key you want to remove
        localStorage.removeItem('toursSelection'); // Replace 'keyName' with the name of the key you want to remove
        localStorage.removeItem('devkitCurrentChecklist'); // Replace 'keyName' with the name of the key you want to remove
    }

    clearClickConnector() {
        ChatWidget.resetSession();
    }
}

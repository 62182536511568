import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from './loader.service';
import { LoaderState } from './loader';
@Component({
    selector: 'angular-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {
    show = false;
    showHeader = false;
    private subscription: Subscription;
    private headerSubscription: Subscription;
    @Input() showLoader: boolean;
    constructor(
        private loaderService: LoaderService
    ) { }
    ngOnInit() {
        if (this.showLoader) {
            this.show = this.showLoader;
        }
        else {
            this.subscription = this.loaderService.loaderState
                .subscribe((state: LoaderState) => {
                    this.show = state.show;
                });

            this.headerSubscription = this.loaderService.loaderHeaderState.subscribe((state: LoaderState) => {
                this.showHeader = state.show;
            });
        }


    }
    ngOnDestroy() {
        if (this.showLoader) {
        }
        else {
            this.subscription.unsubscribe();
        }
        if (this.headerSubscription)
            this.headerSubscription.unsubscribe();

    }
}

import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GoogleTagManagerService {

  constructor() { }


  loadGTMContainer() {
    // Load GTM container code here if needed
    // Replace 'GTM-XXXXXX' with your GTM container ID
    const gtmCode = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${environment.GoogleTag}');`;

    const gtmNoScript = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=${environment.GoogleTag}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

    const gtmScriptElement = document.createElement('script');
    gtmScriptElement.innerHTML = gtmCode;
    document.head.appendChild(gtmScriptElement);

    const gtmNoScriptElement = document.createElement('noscript');
    gtmNoScriptElement.innerHTML = gtmNoScript;
    document.body.appendChild(gtmNoScriptElement);
  }

}


@Injectable({
  providedIn: 'root',
})
export class DataLayerService {
  private dataLayer: any;

  constructor() {
    this.dataLayer = (window as any).dataLayer || [];
  }

  pushEvent(event: string, data: any) {
    this.dataLayer.push({
      event: event,
      ...data,
    });
  }
}
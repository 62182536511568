export class CablePullingXmlInput {
    InputxmlValue?: string;
    OutputxmlValue?: string;
    SwitchboardCount?: string;
    sld?: string;
    Supply?: string;
    TreeValue?: string;
    maxDemandCalcData?: string;
    cableSizeData?: string;
    id: number;
    ProjId: number;
    HVFuseXml: string;
    HVFuseTrippingXml: string;
}

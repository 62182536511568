import { PermissionCheckerService } from '@abp/auth/permission-checker.service';
import { FeatureCheckerService } from '@abp/features/feature-checker.service';
import { LocalizationService } from '@abp/localization/localization.service';
import { MessageService } from '@abp/message/message.service';
import { AbpMultiTenancyService } from '@abp/multi-tenancy/abp-multi-tenancy.service';
import { NotifyService } from '@abp/notify/notify.service';
import { SettingService } from '@abp/settings/setting.service';
import { Injector, ViewChild } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppUrlService } from '@shared/common/nav/app-url.service';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { AppUiCustomizationService } from '@shared/common/ui/app-ui-customization.service';
import { PrimengTableHelper } from 'shared/helpers/PrimengTableHelper';
import { UtilsService } from '@abp/utils/utils.service';
import { SubscriptionServiceProxy, UserLoginInfoDto } from '@shared/service-proxies/service-proxies';
import { CompanyAccountService } from '@shared/service-proxies/company-account.service';
import { finalize } from 'rxjs/operators';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';
import { Router } from '@angular/router';
import { LoaderService } from '@app/main/cablepro/loader.service';
import { ConfirmationModalComponent } from '@app/shared/common/confirmation-modal/confirmation-modal.component';
import { ModalPopupService } from '@app/main/cablepro/modal-popup.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
export abstract class AppComponentBase {

    localizationSourceName = AppConsts.localization.defaultLocalizationSourceName;

    localization: LocalizationService;
    permission: PermissionCheckerService;
    feature: FeatureCheckerService;
    notify: NotifyService;
    setting: SettingService;
    message: MessageService;
    multiTenancy: AbpMultiTenancyService;
    appSession: AppSessionService;
    primengTableHelper: PrimengTableHelper;
    ui: AppUiCustomizationService;
    appUrlService: AppUrlService;
    _utilsService: UtilsService;
    _modalPopupService: ModalPopupService;
    ngbModalService: NgbModal;
    _appAuthService: AppAuthService;
    @ViewChild('confirmationModal')
    modalComponent!: ConfirmationModalComponent;

    constructor(injector: Injector) {
        this.localization = injector.get(LocalizationService);
        this.permission = injector.get(PermissionCheckerService);
        this.feature = injector.get(FeatureCheckerService);
        this.notify = injector.get(NotifyService);
        this.setting = injector.get(SettingService);
        this.message = injector.get(MessageService);
        this.multiTenancy = injector.get(AbpMultiTenancyService);
        this.appSession = injector.get(AppSessionService);
        this.ui = injector.get(AppUiCustomizationService);
        this.appUrlService = injector.get(AppUrlService);
        this.primengTableHelper = new PrimengTableHelper();
        this._utilsService = injector.get(UtilsService);
        this._modalPopupService = injector.get(ModalPopupService);
        this._appAuthService = injector.get(AppAuthService);
        this.ngbModalService = injector.get(NgbModal);
    }

    l(key: string, ...args: any[]): string {
        args.unshift(key);
        args.unshift(this.localizationSourceName);
        return this.ls.apply(this, args);
    }

    ls(sourcename: string, key: string, ...args: any[]): string {
        let localizedText = this.localization.localize(key, sourcename);

        if (!localizedText) {
            localizedText = key;
        }

        if (!args || !args.length) {
            return localizedText;
        }

        args.unshift(localizedText);
        return abp.utils.formatString.apply(this, args);
    }

    isGranted(permissionName: string): boolean {
        return this.permission.isGranted(permissionName);
    }

    isGrantedAny(...permissions: string[]): boolean {
        if (!permissions) {
            return false;
        }

        for (const permission of permissions) {
            if (this.isGranted(permission)) {
                return true;
            }
        }

        return false;
    }

    s(key: string): string {
        return abp.setting.get(key);
    }

    appRootUrl(): string {
        return this.appUrlService.appRootUrl;
    }

    freeTrialLimitationExceededFlow(user: UserLoginInfoDto, standards_CableSizing: number, _companyAccountService: CompanyAccountService, _router: Router) {
        if (user) {
            let isAdminUser = (user.id === 1 || user.id === 2);
            if (!isAdminUser) {
                if (user.premiumUser) {
                    if (user.isPlanAddedManually || !user.companyId || user.companyId <= 0 || (user.companyId > 0 && (user.isAccountOwner || user.isBillingManager))) {
                        $('#purchase-restriction-model').modal('show');
                    }
                    else if (user.companyId > 0 && (!user.isAccountOwner && !user.isBillingManager)) {
                        this._modalPopupService.confirm("Free Trial Limitation", "This feature is not available in your company’s account. Please ask them to upgrade. <br> <br>Alternatively, you can buy your own licence to access the features but this will remove you from the team account.", (value) => {
                            if (!value) {
                                setTimeout(() => {
                                    this._modalPopupService.confirm("Leave the Company Account", "This action will remove you from the company account. Are you sure you want to proceed?", (value) => {
                                        if (value) {
                                            let obj = { companyId: user.companyId, userId: user.id };
                                            _companyAccountService.RemoveFromTeamBySelf(obj).pipe(finalize(() => { })).subscribe((result: any) => {
                                                if (result) {
                                                    window.location.reload();
                                                }
                                            });
                                        }
                                    }, "fa-warning text-warning", "Proceed", undefined, "Cancel");
                                }, 500);
                            }
                        }, "fa-times-circle text-danger", "Close", "btn-primary", "Buy Licence")
                    }
                }
                else {
                    this._modalPopupService.confirm("Free Trial Limitation", "Please purchase a plan.", (value) => {
                        if (value) {
                            this.GoToPricing(standards_CableSizing, _router);
                        }
                    }, "fa-times-circle text-danger", "Go to Pricing", "btn-primary", "Cancel");
                }

            }
        }
    }

    GoToPricing(standards_CableSizing: number, _router: Router): void {

        if (standards_CableSizing === 0 || standards_CableSizing === 1)
            _router.navigate(['/app/main/projects/', 'subscriptionplans']);
        else if (standards_CableSizing === 2)
            _router.navigate(['/app/main/projects/', 'bscalcsubscriptionplans']);
        // }
    };

    ManageSubscription(user: UserLoginInfoDto, loaderService: LoaderService, _subscriptionService: SubscriptionServiceProxy, type: string = '') {
        if (user.isPlanAddedManually) {
            this._modalPopupService.error("Manage Subscription", "Your subscription has been paid via direct invoice. Please contact <a href='mailto:sales@elek.com'>sales@elek.com</a> to make changes.", "Close");
        }
        else {
            if (user.userStripeCustomerId && user.userStripeSubscriptionId) {
                if (user.isAccountOwner || user.isBillingManager || user.companyId <= 0) {
                    loaderService.show();
                    _subscriptionService.manageSubscription(user.userStripeCustomerId, user.userStripeSubscriptionId, type).subscribe(result => {
                        loaderService.hide();
                        if (result) {
                            window.location.href = result;
                        }
                        else
                            this.notify.error(this.l('AnErrorOccurred'));
                    });
                }
                else {
                    this._modalPopupService.billingManagerPrivilegesRequired();
                }
            }
        }
    }

    handleUnlicencesOrExpiredFlow(user: UserLoginInfoDto, standards_CableSizing: number, _companyAccountService: CompanyAccountService, _router: Router) {
        if (user) {
            let isAdminUser = (user.id === 1 || user.id === 2);
            if (!isAdminUser) {
                if (!user.companyId || user.companyId <= 0 || (user.companyId > 0 && user.isLicenseGranted && (user.isAccountOwner || user.isBillingManager))) {
                    //Individual user or team user with billing manager privileges.
                    this._modalPopupService.error("Your Account is Unlicensed", "Please purchase a plan.", "Go to Pricing", (result) => {
                        if (result) {
                            this.GoToPricing(standards_CableSizing, _router);
                        }
                        else
                            this.logout();
                    }, false);
                }
                else if (user.companyId > 0 && ((!user.isAccountOwner && !user.isBillingManager) || !user.isLicenseGranted)) {
                    // Team user without billing manager privileges
                    this._modalPopupService.confirm("You are an Unlicensed Team User", "Please request a licence from your Team manager. <br> <br>Alternatively, you can buy your own licence but this will remove you from the team account.", (value) => {
                        if (value) {
                            this.logout();
                        }
                        else {
                            setTimeout(() => {
                                this._modalPopupService.confirm("Leave the Company Account", "This action will remove you from the company account. Are you sure you want to proceed?", (value) => {
                                    if (value) {
                                        let obj = { companyId: user.companyId, userId: user.id };
                                        _companyAccountService.RemoveFromTeamBySelf(obj).pipe(finalize(() => { })).subscribe((result: any) => {
                                            if (result) {
                                                window.location.reload();
                                            }
                                        });
                                    }
                                    else {
                                        this.logout();
                                    }
                                }, "fa-warning text-warning", "Proceed", undefined, "Cancel");
                            }, 500);
                        }
                    }, "fa-times-circle text-danger", "Logout", "btn-primary", "Buy Licence");
                }
            }
        }
    }

    handleFailedOrUnpaidFlow(user: UserLoginInfoDto, loaderService: LoaderService, _subscriptionService: SubscriptionServiceProxy, _companyAccountService: CompanyAccountService, _router: Router) {
        if (user) {
            let isAdminUser = (user.id === 1 || user.id === 2);
            if (!isAdminUser) {
                if (!user.companyId || user.companyId <= 0 || (user.companyId > 0 && user.isLicenseGranted && (user.isAccountOwner || user.isBillingManager))) {
                    //Individual user or team user with billing manager privileges.
                    this._modalPopupService.confirm("Payment Overdue", "Please update the account’s payment method. In the next window, you will be able to update your credit card details.", (value) => {
                        if (value) {
                            this.ManageSubscription(user, loaderService, _subscriptionService);
                        }
                        else {
                            this.logout();
                        }
                    }, "fa-times-circle text-danger", "Update", "btn-primary", "Logout");
                }
                else if (user.companyId > 0 && ((!user.isAccountOwner && !user.isBillingManager) || !user.isLicenseGranted)) {
                    // Team user without billing manager privileges
                    this._modalPopupService.confirm("You are an Unlicensed Team User", "Your company’s account has not been paid. Please ask the account owner to update their payment method. <br> <br>Alternatively, you can buy your own licence but this will remove you from the team account.", (value) => {
                        if (value) {
                            this.logout();
                        }
                        else {
                            setTimeout(() => {
                                this._modalPopupService.confirm("Leave the Company Account", "This action will remove you from the company account. Are you sure you want to proceed?", (value) => {
                                    if (value) {
                                        let obj = { companyId: user.companyId, userId: user.id };
                                        _companyAccountService.RemoveFromTeamBySelf(obj).pipe(finalize(() => { })).subscribe((result: any) => {
                                            if (result) {
                                                window.location.reload();
                                            }
                                        });
                                    }
                                    else {
                                        this.logout();
                                    }
                                }, "fa-warning text-warning", "Proceed", undefined, "Cancel");
                            }, 500);
                        }
                    }, "fa-times-circle text-danger", "Logout", "btn-primary", "Buy Licence");
                }
            }
        }
    }

    UpgradeSubscription(user: UserLoginInfoDto, loaderService: LoaderService, _subscriptionService: SubscriptionServiceProxy, _companyAccountService: CompanyAccountService) {
        if (user) {
            if (!user.companyId || user.companyId <= 0 || (user.companyId > 0 && (user.isAccountOwner || user.isBillingManager))) {
                //Individual User or company user with billing manager privileges
                this.ManageSubscription(user, loaderService, _subscriptionService, "subscription");
            }
            else if (user.companyId > 0 && (!user.isAccountOwner && !user.isBillingManager)) {
                this._modalPopupService.confirm("Upgrade your Plan", "Please request an upgrade of the edition from the account’s billing manager. <br> <br>Alternatively, you can buy your own upgraded licence but this will remove you from the team account.", (value) => {
                    if (!value) {
                        setTimeout(() => {
                            this._modalPopupService.confirm("Leave the Company Account", "This action will remove you from the company account. Are you sure you want to proceed?", (value) => {
                                if (value) {
                                    let obj = { companyId: user.companyId, userId: user.id };
                                    _companyAccountService.RemoveFromTeamBySelf(obj).pipe(finalize(() => { })).subscribe((result: any) => {
                                        if (result) {
                                            window.location.reload();
                                        }
                                    });
                                }
                            }, "fa-warning text-warning", "Proceed", undefined, "Cancel");
                        }, 500);
                    }
                }, "fa-warning text-warning", "Close", "btn-primary", "Buy Licence");
            }
        }
    }

    logout(): void {
        if (localStorage.getItem("ReportSettingEmails"))
            localStorage.removeItem("ReportSettingEmails");

        this._utilsService.deleteCookie(AppConsts.productLife.ssoTokenKey);
        this._appAuthService.logout();
    }

    downloadTextFile(tabDelimitedContent, filename) {
        const blob = new Blob([tabDelimitedContent], { type: 'text/plain' });
        const link = document.createElement('a');
        link.download = filename;
        link.href = window.URL.createObjectURL(blob);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}

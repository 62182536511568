import { Inject, Injectable, Injector, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';


import { AppAuthService } from '@app/shared/common/auth/app-auth.service';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import { environment } from 'environments/environment';
import { API_BASE_URL } from './service-proxies';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private baseUrl: string;
    constructor(injector: Injector, private http: HttpClient, private _authService: AppAuthService, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
        this.baseUrl = baseUrl ? baseUrl : "";
    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(
            (error: any, caught: Observable<HttpEvent<any>>) => {
                const isExternalCall = this.baseUrl && !request.url.startsWith(this.baseUrl);
                if (isExternalCall) {
                    return of();
                }


                if (error.status === 401) {
                    this._authService.logout(true);
                    localStorage.setItem("showLogoutMsg", 'true');
                    return of(error);
                }
                else if (error.status === 0) {
                    if (UrlHelper.isMaintenanceUrl(location.href))
                        return of();
                    else if (UrlHelper.isApiStatusUrl(request.url))
                        window.location.href = '/maintenance';
                    //return of(error);
                }
                throw error;
            }
        ));
    }
}
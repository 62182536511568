import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[emailMultiple][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: EmailMultipleDirective,
      multi: true
    }
  ]
})
export class EmailMultipleDirective implements Validator {

  validate(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }

    // Split by comma or newline
    const emails = control.value.split(/[,|\n]+/).map(email => email.trim());

    const invalidEmails = emails.filter(email => {
      // Using a basic email pattern for validation here
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return !emailPattern.test(email);
    });

    if (invalidEmails.length > 0) {
      return { invalidEmails: true };
    }

    const emails1 = control.value.split(/[,|\n]+/).map(email => email.trim().toLowerCase()).filter(email => email.toLowerCase());
    const emailSet = new Set<string>();
    let duplicateEmails = [];

    emails1.forEach(email => {
      if (emailSet.has(email)) {
        duplicateEmails.push(email);
      } else {
        emailSet.add(email);
      }
    });

    if (duplicateEmails.length > 0) {
      return { duplicateEmails: true };
    }

    return null;
  }

}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LoaderState } from './loader';
@Injectable()
export class LoaderService {
    private loaderSubject = new Subject<LoaderState>();
    loaderState = this.loaderSubject.asObservable();

    private loaderHeaderSubject = new Subject<LoaderState>();
    loaderHeaderState = this.loaderHeaderSubject.asObservable();
    constructor() { }
    show() {
        this.loaderSubject.next(<LoaderState>{ show: true });
    }
    hide() {
        this.loaderSubject.next(<LoaderState>{ show: false });
    }

    showHeader() {
        this.loaderHeaderSubject.next(<LoaderState>{ show: true });
    }
    hideHeader() {
        this.loaderHeaderSubject.next(<LoaderState>{ show: false });
    }
}
import { mergeMap as _observableMergeMap, catchError as _observableCatch } from 'rxjs/operators';
import { Observable, from as _observableFrom, throwError as _observableThrow, of as _observableOf } from 'rxjs';
import { Injectable, Inject, Optional, InjectionToken } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpResponseBase, HttpErrorResponse } from '@angular/common/http';
import * as moment from 'moment';
export const API_BASE_URL = new InjectionToken<string>('API_BASE_URL');
import { CalculationReportInput, SwaggerException,FileDto } from './service-proxies';
import { AppConsts } from '@shared/AppConsts';
@Injectable()
export class PdfReportServiceProxy {
    private http: HttpClient;
    private baseUrl: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;
    
    constructor(@Inject(HttpClient) http: HttpClient, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
        this.http = http;
        this.baseUrl = baseUrl ? baseUrl : AppConsts.remoteServiceBaseUrl;
    }

    /**
     * @calculationReportInput (optional) 
     * @return Success
     */
    CustomGenerateReport(calculationReportInput: CalculationReportInput | null | undefined): Observable<any> {
        let url_ = this.baseUrl + "/api/services/app/CableSizeReport/GenerateReport";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(calculationReportInput);
        let options_: any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
            })
        };
        return this.http.request("post", url_, options_);
    }
    GenerateReportFromS3(calculationReportInput: CalculationReportInput | null | undefined): Observable<any> {
        let url_ = this.baseUrl + "/api/services/app/Cablepro/DownloadReportFromS3";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(calculationReportInput);
        let options_: any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
            })
        };
        return this.http.request("post", url_, options_);
    }
    DownloadZipOfReportFromS3(calculationReportInput: CalculationReportInput | null | undefined): Observable<any> {
        let url_ = this.baseUrl + "/api/services/app/Cablepro/DownloadZipOfReportFromS3";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(calculationReportInput);
        let options_: any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
            })
        };
        return this.http.request("post", url_, options_);
    }


    CustomGenerateVoltageRiseReport(calculationReportInput: CalculationReportInput | null | undefined): Observable<Blob> {
        let url_ = this.baseUrl + "/api/services/app/VoltageRiseReport/GenerateVoltageRiseReport";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(calculationReportInput);
        let options_: any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
            })
        };

        return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_: any) => {
            return this.processGenerateReport(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processGenerateReport(<any>response_);
                } catch (e) {
                    return <Observable<Blob>><any>_observableThrow(e);
                }
            } else
                return <Observable<Blob>><any>_observableThrow(response_);
        }));
    }

        /**
     * @calculationReportInput (optional) 
     * @return Success
     */
         CustomCablePullingGenerateReport(cablePullingReportInput: any | null | undefined): Observable<any> {
            let url_ = this.baseUrl + "/api/services/app/CablePullingReport/GenerateCablePullingReport";
            url_ = url_.replace(/[?&]$/, "");
    
            const content_ = JSON.stringify(cablePullingReportInput);
    
            let options_: any = {
                body: content_,
                observe: "response",
                responseType: "blob",
                headers: new HttpHeaders({
                    "Content-Type": "application/json",
                })
            };
    
            return this.http.request("post", url_, options_);
        }


    /**
     * @calculationReportInput (optional) 
     * @return Success
     */
    CustomMaxDemandGenerateReport(calculationReportInput: any | null | undefined): Observable<any> {
        let url_ = this.baseUrl + "/api/services/app/Setting/GenerateMaxDemandReport";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(calculationReportInput);

        let options_: any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
            })
        };

        return this.http.request("post", url_, options_);
    }
    CustomAutoMaxDemandGenerateReport(calculationReportInput: any | null | undefined): Observable<any> {
        let url_ = this.baseUrl + "/api/services/app/AutoMaxDemandReport/GenerateAutoMaxDemandReport";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(calculationReportInput);

        let options_: any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
            })
        };
        return this.http.request("post", url_, options_);
    }
    ArcFlashGenerateReport(calculationReportInput: any | null | undefined): Observable<any> {
        let url_ = this.baseUrl + "/api/services/app/ArcFlashReport/GenerateArcFlashReport";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(calculationReportInput);

        let options_: any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
            })
        };
        return this.http.request("post", url_, options_);
    }
    ProtectiveGenerateReport(calculationReportInput: any | null | undefined): Observable<any> {
        let url_ = this.baseUrl + "/api/services/app/ProtectiveDeviceReport/GeneratePReport";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(calculationReportInput);

        let options_: any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
            })
        };
        return this.http.request("post", url_, options_);
    }

    ProtectiveCoordinationGenerateReport(calculationReportInput: any | null | undefined): Observable<any> {
        let url_ = this.baseUrl + "/api/services/app/ProtectiveDeviceReport/GeneratePReportForCoordinationPopup";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(calculationReportInput);

        let options_: any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
            })
        };
        return this.http.request("post", url_, options_);
    }
    NetworkCalculationGenerateReport(calculationReportInput: any | null | undefined): Observable<any> {
        let url_ = this.baseUrl + "/api/services/app/NetworkCalculationReport/NetworkCalculationGenerateReport";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(calculationReportInput);

        let options_: any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
            })
        };
        return this.http.request("post", url_, options_);
    }
    /**
     * @calculationReportInput (optional) 
     * @return Success
     */
    CustomBSCalculationGenerateReport(calculationReportInput: CalculationReportInput | null | undefined): Observable<Blob> {
        let url_ = this.baseUrl + "/api/services/app/CableSizeReport/GenerateReport";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(calculationReportInput);

        let options_: any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
            })
        };

        return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_: any) => {
            return this.processGenerateReport(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processGenerateReport(<any>response_);
                } catch (e) {
                    return <Observable<Blob>><any>_observableThrow(e);
                }
            } else
                return <Observable<Blob>><any>_observableThrow(response_);
        }));
    }
    //#region  Cable schedule export to excel sheet
    /**
     * @return Success
     */
    CableScheduleExportToExcel(calculationExportInput: CalculationReportInput[] | null | undefined): Observable<FileDto> {
        let url_ = this.baseUrl + "/api/services/app/Cablepro/CableScheduleExportToExcel";
          url_ = url_.replace(/[?&]$/, "");
    
          const content_ = JSON.stringify(calculationExportInput);

        let options_: any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };
            return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_: any) => {
                return this.processCableSchedulesToExcel(response_);
            })).pipe(_observableCatch((response_: any) => {
                if (response_ instanceof HttpResponseBase) {
                    try {
                        return this.processCableSchedulesToExcel(<any>response_);
                    } catch (e) {
                        return <Observable<FileDto>><any>_observableThrow(e);
                    }
                } else
                    return <Observable<FileDto>><any>_observableThrow(response_);
            }));
        }

        ArcFlashExportToExcel(calculationReportInput: any | null | undefined): Observable<any> {
            let url_ = this.baseUrl + "/api/services/app/Setting/ArcFlashExportToExcel";
            url_ = url_.replace(/[?&]$/, "");
           
        
              const content_ = JSON.stringify(calculationReportInput);
    
            let options_: any = {
                body: content_,
                observe: "response",
                responseType: "blob",
                headers: new HttpHeaders({
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                })
            };
                return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_: any) => {
                    return this.processCableSchedulesToExcel(response_);
                })).pipe(_observableCatch((response_: any) => {
                    if (response_ instanceof HttpResponseBase) {
                        try {
                            return this.processCableSchedulesToExcel(<any>response_);
                        } catch (e) {
                            return <Observable<FileDto>><any>_observableThrow(e);
                        }
                    } else
                        return <Observable<FileDto>><any>_observableThrow(response_);
                }));
            }

            NetworkCableScheduleExportToExcel(calculationReportInput: any | null | undefined): Observable<any> {
                let url_ = this.baseUrl + "/api/services/app/Setting/NetworkCableScheduleExportToExcel";
                url_ = url_.replace(/[?&]$/, "");
               
            
                  const content_ = JSON.stringify(calculationReportInput);
        
                let options_: any = {
                    body: content_,
                    observe: "response",
                    responseType: "blob",
                    headers: new HttpHeaders({
                        "Content-Type": "application/json",
                        "Accept": "application/json"
                    })
                };
                    return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_: any) => {
                        return this.processCableSchedulesToExcel(response_);
                    })).pipe(_observableCatch((response_: any) => {
                        if (response_ instanceof HttpResponseBase) {
                            try {
                                return this.processCableSchedulesToExcel(<any>response_);
                            } catch (e) {
                                return <Observable<FileDto>><any>_observableThrow(e);
                            }
                        } else
                            return <Observable<FileDto>><any>_observableThrow(response_);
                    }));
                }
        protected processCableSchedulesToExcel(response: HttpResponseBase): Observable<FileDto> {
            const status = response.status;
            const responseBlob =
                response instanceof HttpResponse ? response.body :
                    (<any>response).error instanceof Blob ? (<any>response).error : undefined;
    
            let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } };
            if (status === 200) {
                return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                    let result200: any = null;
                    let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
                    result200 = resultData200 ? FileDto.fromJS(resultData200) : new FileDto();
                    return _observableOf(result200);
                }));
            } else if (status !== 200 && status !== 204) {
                return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                    return throwException("An unexpected server error occurred.", status, _responseText, _headers);
                }));
            }
            return _observableOf<FileDto>(<any>null);
        }
    //#endregion
    

    protected processGenerateReport(response: HttpResponseBase): Observable<Blob> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
                (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } };
       
        if (status === 200) {
            return _observableOf<Blob>(responseBlob);
        } else if (status !== 200 && status !== 204) {
            return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<Blob>(<any>null);
    }
}




function throwException(message: string, status: number, response: string, headers: { [key: string]: any; }, result?: any): Observable<any> {
    if (result !== null && result !== undefined)
        return _observableThrow(result);
    else
        return _observableThrow(new SwaggerException(message, status, response, headers, null));
}

function blobToText(blob: any): Observable<string> {
    return new Observable<string>((observer: any) => {
        if (!blob) {
            observer.next("");
            observer.complete();
        } else {
            let reader = new FileReader();
            reader.onload = function () {
                observer.next(this.result);
                observer.complete();
            }
            reader.readAsText(blob);
        }
    });
}


import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

@Injectable({    
    providedIn: 'root'    
  })    
  export class EventEmitterService {    

    invokeFirstComponentFunction = new EventEmitter();
    invokeFirstComponentFunction1 = new EventEmitter();
    subsVar: Subscription; 
    subsVar1: Subscription; 

    private messageSource = new BehaviorSubject('default message');
    currentMessage = this.messageSource.asObservable();
      
    constructor() { }    
    changeMessage(message: string) {
        this.messageSource.next(message)
    }
    unSubscribe() {
      this.currentMessage= new BehaviorSubject('default message');
      this.invokeFirstComponentFunction = new EventEmitter();
      this.invokeFirstComponentFunction1 = new EventEmitter();
      this.subsVar=undefined;
      this.subsVar1=undefined;
     // this.messageSource.unsubscribe();
  }
  onFirstComponentButtonClick() {    
    this.invokeFirstComponentFunction.emit();    
  } 
  onFirstComponentButtonClick1() {    
    this.invokeFirstComponentFunction1.emit();    
  } 
  } 
// navigation.service.ts
import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private history: string[] = [];
  private externalReferrer: string;

  constructor(private router: Router) {
    this.externalReferrer = document.referrer;
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
      }
    });
  }

  getHistory(): string[] {
    return this.history;
  }

  getPreviousUrl(): string {
    if (this.history.length > 1) {
      return this.history[this.history.length - 2];
    } else if (this.history.length === 1 && this.externalReferrer) {
      return this.externalReferrer;
    }
    return '';
  }

  clearHistory() {
    this.history = [];
  }
}

import { AbpSessionService } from '@abp/session/abp-session.service';
import { Component, ElementRef, HostListener, Injector, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { accountModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AccountServiceProxy, CodeProvider, CommonLookupServiceProxy, PasswordlessAuthenticateModel, SendPasswordlessLoginCodeInput, SessionServiceProxy, SocialSigninModel, UpdateUserSignInTokenOutput } from '@shared/service-proxies/service-proxies';
import { UrlHelper } from 'shared/helpers/UrlHelper';
import { ExternalLoginProvider, LoginService } from './login.service';
import { SocialAuthService } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { NgForm } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { interval, Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { MessageService } from 'abp-ng2-module/dist/src/message/message.service';
import { NgOtpInputComponent } from 'ng-otp-input';


@Component({
  templateUrl: './login.component.html',
  animations: [accountModuleAnimation()],
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends AppComponentBase implements OnInit {
  submitting = false;
  isLoginClicked: boolean = false;
  isMultiTenancyEnabled: boolean = this.multiTenancy.isEnabled;
  authLibraryReady: boolean = false;
  username: string;
  password: string;
  public isLoginWithEmail: boolean = false;
  public isPasswordlessCodeSent: boolean = false;
  public isMobile = false;
  public selectedCountry: string = 'AU';
  public phoneNumber: string = '(+61) 02 8231 6673';
  canResend: boolean = false;
  countdown: number = 30;
  countdownSubscription: Subscription;
  passwordLessCodeInvalid: boolean = false;
  passwordLessCode: string = '';
  @ViewChild(NgOtpInputComponent, { static: false }) ngOtpInput: NgOtpInputComponent;

  constructor(
    injector: Injector,
    public loginService: LoginService,
    private _sessionService: AbpSessionService,
    private _accountService: AccountServiceProxy,
    private _sessionAppService: SessionServiceProxy,
    private authService: SocialAuthService,
    private _commonLookupServiceProxy: CommonLookupServiceProxy,
    private _messageService: MessageService,
    private titleService: Title
  ) {
    super(injector);
    this.authService.initState.subscribe(next => { this.authLibraryReady = next })
    this.checkDevice();
    this.getCurrentCountry();
    this.titleService.setTitle("ELEK Cable Pro Software - Login");
  }

  checkDevice() {
    const screenHeight = window.innerHeight;
    const screenWidth = window.innerWidth;
    let prev = this.isMobile;
    if (screenWidth > 767) {
      this.isMobile = false;
    }
    else {
      this.isMobile = true;
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.checkDevice();
  }

  get multiTenancySideIsTeanant(): boolean {
    return this._sessionService.tenantId > 0;
  }

  get isTenantSelfRegistrationAllowed(): boolean {
    return this.setting.getBoolean('App.TenantManagement.AllowSelfRegistration');
  }

  get isSelfRegistrationAllowed(): boolean {
    if (!this._sessionService.tenantId) {
      return false;
    }

    return this.setting.getBoolean('App.UserManagement.AllowSelfRegistration');
  }

  ngOnInit(): void {
    this.isLoginClicked = false;
    this.loginService.authenticateModel.userNameOrEmailAddress = '';
    this.loginService.authenticateModel.password = '';
    if (this._sessionService.userId > 0 && UrlHelper.getReturnUrl() && UrlHelper.getSingleSignIn()) {
      this._sessionAppService.updateUserSignInToken()
        .subscribe((result: UpdateUserSignInTokenOutput) => {
          const initialReturnUrl = UrlHelper.getReturnUrl();
          const returnUrl = initialReturnUrl + (initialReturnUrl.indexOf('?') >= 0 ? '&' : '?') +
            'accessToken=' + result.signInToken +
            '&userId=' + result.encodedUserId +
            '&tenantId=' + result.encodedTenantId;

          location.href = returnUrl;
        });
    }
  }

  login(frm: NgForm): void {
    this.isLoginClicked = true;
    if (frm.valid) {
      if (localStorage.getItem("ReportSettingEmails"))
        localStorage.removeItem("ReportSettingEmails");

      this.submitting = true;
      this.loginService.authenticateModel.userNameOrEmailAddress = this.username;
      this.loginService.authenticateModel.password = btoa(this.password);
      this.loginService.authenticate(
        () => this.submitting = false, "/app/main/usersetup"
      );
    }
    else {
      this._messageService.error("Please enter Email/Username and Password");
    }
  }

  socialSignin(): void {
    if (localStorage.getItem("ReportSettingEmails"))
      localStorage.removeItem("ReportSettingEmails");

    this.submitting = true;
    this.loginService.socialSignin(
      () => this.submitting = false, "/app/main/usersetup"
    );
  }

  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then((data) => {
      let socialUser = data;
      if (socialUser && socialUser.id) {
        var socialsigninModel = new SocialSigninModel();
        socialsigninModel.userNameOrEmailAddress = socialUser.email;
        socialsigninModel.googleId = socialUser.id;
        this.loginService.socialSigninModel = socialsigninModel;
        this.socialSignin();
      }
    });
  }

  signInWithFacebook() {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID, { auth_type: 'reauthenticate' }).then((data) => {
      let socialUser = data;
      if (socialUser && socialUser.id) {
        var socialsigninModel = new SocialSigninModel();
        socialsigninModel.userNameOrEmailAddress = socialUser.email;
        socialsigninModel.facebookId = socialUser.id;
        this.loginService.socialSigninModel = socialsigninModel;
        this.socialSignin();
      }
    });
  }

  sendPasswordlessLoginCodeInput(isFirstTime: boolean = false): void {
    this.isLoginClicked = false;
    if (this.username && ((this.countdown <= 0 && this.canResend == true) || isFirstTime)) {
      const model = new SendPasswordlessLoginCodeInput();
      model.providerValue = this.username;
      model.providerType = CodeProvider.PasswordlessLogin;
      this.submitting = true;
      this._accountService.sendPasswordlessLoginCode(model).pipe(finalize(() => (this.submitting = false))).subscribe((res: boolean) => {
        if (res == true) {
          this.isPasswordlessCodeSent = true;
          if (isFirstTime) {
            this.passwordLessCode = undefined;
          }

          this.startCountdown();
        }
      });
    }
    else {
      if (!this.username) {
        this._messageService.error("Please enter Email/Username");
      }
    }
  }

  verifyPasswordlessLogin() {
    this.passwordLessCodeInvalid = false;
    if (this.passwordLessCode && this.passwordLessCode.length == 6 && !this.submitting) {
      this.submitting = true;
      const passwordlessAuthenticateModel = new PasswordlessAuthenticateModel();
      const initialReturnUrl = UrlHelper.getReturnUrl();
      passwordlessAuthenticateModel.providerValue = this.username;
      passwordlessAuthenticateModel.verificationCode = this.passwordLessCode;
      passwordlessAuthenticateModel.singleSignIn = UrlHelper.getSingleSignIn();
      passwordlessAuthenticateModel.captchaResponse = initialReturnUrl;


      this.loginService.passwordlessAuthenticate(
        (isError: boolean = false) => {
          if (isError == true) {
            this.passwordLessCode = undefined;
            this.ngOtpInput.setValue('');
            this.submitting = false;
          }
        }, passwordlessAuthenticateModel, "/app/main/usersetup", false
      );

    }
    else {
      this.passwordLessCodeInvalid = true;
    }
  }

  getCurrentCountry() {
    this._commonLookupServiceProxy.getIpInfo().subscribe((response) => {
      if (response && response.isSuccess) {
        if (response.data && response.data.country) {
          this.selectedCountry = response.data.country;
          if (this.selectedCountry == "GB") {
            this.phoneNumber = "(+44) 020 3603 5333";
          }
          else if (this.selectedCountry == "US") {
            this.phoneNumber = "(+1) 888 226 9651";
          }
        }
      }
    });
  }

  startCountdown() {
    this.canResend = false;
    this.countdown = 30;
    if (this.countdownSubscription) {
      this.countdownSubscription.unsubscribe();
    }
    this.countdownSubscription = interval(1000).subscribe(() => {
      this.countdown--;
      if (this.countdown === 0) {
        this.canResend = true;
        this.countdownSubscription.unsubscribe();
      }
    });
  }

  onOtpChange(input) {
    this.passwordLessCode = input;
    if (this.passwordLessCode && this.passwordLessCode.length == 6) {
      this.verifyPasswordlessLogin();
    }
  }

  @ViewChild('imageBoxContent') content!: ElementRef;
  ngAfterViewInit() {
    this.updateContentHeight();
    window.addEventListener('resize', this.updateContentHeight.bind(this));
  }

  private updateContentHeight() {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 768 && screenWidth <= 932) {
      const containerHeight = document.querySelector('.right-content')?.clientHeight || 0;
      const contentElement = this.content.nativeElement;

      const otherContentHeight = document.querySelector('.other-text-content')?.clientHeight || 0;

      const availableHeight = containerHeight - otherContentHeight - 50;

      // Set the max-height based on the container's height
      contentElement.style.maxHeight = `${availableHeight}px`;
    }
    else{
      const contentElement = this.content.nativeElement;
      contentElement.style.maxHeight = '';
    }
  }
}

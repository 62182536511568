import { PermissionCheckerService } from '@abp/auth/permission-checker.service';
import { Component, Injector, OnInit, ViewEncapsulation } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { AppUiCustomizationService } from '@shared/common/ui/app-ui-customization.service';
import { AppMenu } from './app-menu';
import { AppNavigationService } from './app-navigation.service';
import { GetCurrentLoginInformationsOutput, ProjectOutput, CalculationOutput, SessionServiceProxy, CalculationInput, UserLoginInfoDto, SubscriptionServiceProxy } from '@shared/service-proxies/service-proxies';
import { CalculationService, PlanService, ProductService, SettingService } from 'services/project.service';
import { Observable, Observer, Subscription } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as moment from 'moment';
import { LoaderService } from '@app/main/cablepro/loader.service';
import { CompanyAccountService } from '@shared/service-proxies/company-account.service';

@Component({
    templateUrl: './side-bar-menu.component.html',
    selector: 'side-bar-menu',
    encapsulation: ViewEncapsulation.None
})
export class SideBarMenuComponent extends AppComponentBase implements OnInit {

    menu: AppMenu = new AppMenu('', '', []);
    public isAdminUser: boolean = false;
    headerlabel: string;
    mode: string;
    recentProjects$: Observable<ProjectOutput[]>;
    recentCalculations$: Observable<CalculationOutput[]>;
    public calculationStandard: number = 0;

    public isPlanAddedManually: boolean = false;
    public hasPlanExpired: boolean = false;
    public planExtendedCount: number = 0;
    public expiredPlanHeadingText: string = '';
    public expiredPlanBodyText: string = '';
    public isLoaderForExtendPlan = false;
    // public isPaymentNotRecieved: boolean = false;
    private planDueDays: number = 0;
    public unRegisteredUser: boolean = false;
    private userStripeCustomerId: string = '';
    public premiumUser!: boolean;
    public user: UserLoginInfoDto;

    constructor(
        injector: Injector,
        private _router: Router,
        public permission: PermissionCheckerService,
        private _appSessionService: AppSessionService,
        private _uiCustomizationService: AppUiCustomizationService,
        private _appNavigationService: AppNavigationService,
        private _sessionService: SessionServiceProxy,
        private _productService: ProductService,
        private _calculationService: CalculationService,
        private activeRoute: ActivatedRoute,
        private _settingService: SettingService,
        private _planService: PlanService,
        private loaderService: LoaderService,
        private _companyAccountService: CompanyAccountService,
        private _subscriptionService: SubscriptionServiceProxy,
    ) {
        super(injector);
        (window as any).sidebar = this;
    }

    ngOnInit() {
        this.menu = this._appNavigationService.getMenu();
        this._settingService.getSettings().subscribe((result) => {
            this.calculationStandard = result.standards_CableSizing || 0;
        });
        this.user = this._appSessionService.user;
        this.isAdminUser = (this._appSessionService.role.roleId === 1 || this._appSessionService.role.roleId === 2);
        this.isPlanAddedManually = this.user.isPlanAddedManually;
        this.premiumUser = this.user.premiumUser;
        this.unRegisteredUser = false;
        this.userStripeCustomerId = this.user.userStripeCustomerId;
        this.planExtendedCount = this.user.trialPeriodExtendedCount;
        if (this.user.periodEndDate != null && this.user.periodEndDate != undefined
            && this.user.periodEndDate == "Inactive") {
            this.expiredPlanHeadingText = 'Your Plan has Expired';
            this.expiredPlanBodyText = 'We have great value monthly and annual plans';
            this.hasPlanExpired = true;
        }
        else {
            if (this.user.periodEndDate != null && this.user.periodEndDate != undefined
                && this.user.periodEndDate != "succeeded") {
            }
        }

        if (this.userStripeCustomerId === null || this.userStripeCustomerId === undefined) {
            this.unRegisteredUser = true;
        }
        else if (this.userStripeCustomerId.trim().length == 0 || this.userStripeCustomerId.trim() == '0') {
            this.unRegisteredUser = true;
        }

        let currentDateTime = moment();
        let expiryDateTime = moment(this.GetDate(this.user.expirationDate));
        let _hasPlanExpired = moment(currentDateTime).isSameOrAfter(expiryDateTime, 'day');
        this.hasPlanExpired = _hasPlanExpired;
        let _planDueDays = moment(currentDateTime).diff(expiryDateTime, 'day');

        if (this.user.periodEndDate != null && this.user.periodEndDate != undefined
            && this.user.periodEndDate == "Inactive") {
            this.hasPlanExpired = true;
        }

        if (!this.isAdminUser) {
            if ((_hasPlanExpired || (_planDueDays >= -7 && _planDueDays <= 0))) {
                this.hasPlanExpired = _hasPlanExpired;
                this.planDueDays = _planDueDays;
            }
        }

        this._productService.refreshSidebarProjects();
        this._calculationService.refreshSidebarCalculations();

        this._productService.recentProjects.subscribe(projects => {
            if (projects)
                this.recentProjects$ = projects;
        });

        this._calculationService.recentCalculations.subscribe(calculations => {
            this.recentCalculations$ = calculations;
        });
    }

    GetDate(str: any) {

        var arr = str.split('/');
        var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        var i = 0;
        for (i; i < months.length; i++) {
            if (months[i] == arr[1]) {
                break;
            }
        }
        var formatddate = (i + 1) + '/' + arr[0] + '/' + arr[2];
        return formatddate;
    }

    showMenuItem(menuItem): boolean {
        // if (this.isAdminUser) {
        if (menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' && this._appSessionService.tenant && !this._appSessionService.tenant.edition) {
            return false;
        }
        // }

        if (menuItem.permissionName) {
            return this.permission.isGranted(menuItem.permissionName);
        }

        if (menuItem.items && menuItem.items.length) {
            // if (this.isAdminUser) {
            return this._appNavigationService.checkChildMenuItemPermission(menuItem);
            // }
            // else {
            //     return this._appNavigationService.checkChildMenuItemPermission(menuItem);
            // }
        }

        return true;
    }

    addNewCalculation(calculationType: number) {
        if (this.hasPlanExpired) {
            this.handleUnlicencesOrExpiredFlow(this.user, this.calculationStandard, this._companyAccountService, this._router)
            return;
        }
        let calculationInput: CalculationInput = new CalculationInput();
        if (calculationType == 1 && this.calculationStandard == 2) {
            calculationInput.calculationType = 3;
        }
        else {
            calculationInput.calculationType = calculationType;
        }
        calculationInput.projectId = 0;
        let params = this.collectRouteParams(this.activeRoute);

        if (params && params["pid"]) {
            calculationInput.projectId = parseInt(params["pid"]);
        }
        this.createCalculation(calculationInput);
    }

    createCalculation(calculationInput: CalculationInput) {
        this._calculationService.createCalculation(calculationInput).subscribe(result => {
            var returnUrl = '';
            var otherParams = '&name=' + result.projectName + '&cname=' + result.name;
            if (calculationInput.calculationType === 1) {
                returnUrl = '?returnUrl=/app/main/projects/' + result.projectId + '/calcs/' + result.id;
            }
            else if (calculationInput.calculationType === 3) {
                returnUrl = '?returnUrl=/app/main/projects/' + result.projectId + '/bscalcs/' + result.id;
            }
            else if (calculationInput.calculationType === 4) {
                returnUrl = '?returnUrl=/app/main/projects/' + result.projectId + '/automaxd/' + result.id;
            }
            else if (calculationInput.calculationType === 5) {
                returnUrl = '?returnUrl=/app/main/projects/' + result.projectId + '/pdccalcs/' + result.id;
            }
            else if (calculationInput.calculationType === 6) {
                returnUrl = '?returnUrl=/app/main/projects/' + result.projectId + '/calcp/' + result.id;
            }
            else if (calculationInput.calculationType === 7) {
                returnUrl = '?returnUrl=/app/main/projects/' + result.projectId + '/networkcalc/' + result.id;
            }
            else if (calculationInput.calculationType === 8) {
                returnUrl = '?returnUrl=/app/main/projects/' + result.projectId + '/arcflash/' + result.id;
            }
            else {
                return;
            }
            // do not append version as it is being appended from routeback component.
            this._router.navigateByUrl('/app/main/routeback' + returnUrl + otherParams);
            this._calculationService.refreshSidebarCalculations();
            this._productService.refreshSidebarProjects();
        });
    }

    SetCalculationLink(calculation): any {
        let calculationType: string = 'calcs';

        if (calculation.calculationType === 2)
            calculationType = 'maxd';
        else if (calculation.calculationType === 3)
            calculationType = 'bscalcs';
        else if (calculation.calculationType === 4)
            calculationType = 'automaxd';
        else if (calculation.calculationType === 5)
            calculationType = 'pdccalcs';
        else if (calculation.calculationType === 6)
            calculationType = 'calcp';
        else if (calculation.calculationType === 7)
            calculationType = 'networkcalc';
        else if (calculation.calculationType === 8)
            calculationType = 'arcflash';

        return ['/app/main/projects/' + calculation.projectId + '/' + calculationType + '/' + calculation.id];
    }

    OpenCalculation(calculation): any {
        if (this.hasPlanExpired) {
            this.handleUnlicencesOrExpiredFlow(this.user, this.calculationStandard, this._companyAccountService, this._router)
            // this.setExpirePlanDetails();
        }
        else {
            var returnUrl = '';
            var otherParams = '&name=' + calculation.projectName + '&cname=' + calculation.name;
            if (calculation.calculationType === 1) {
                returnUrl = '?returnUrl=/app/main/projects/' + calculation.projectId + '/calcs/' + calculation.id;
            }
            else if (calculation.calculationType === 3) {
                returnUrl = '?returnUrl=/app/main/projects/' + calculation.projectId + '/bscalcs/' + calculation.id;
            }
            else if (calculation.calculationType === 4) {
                returnUrl = '?returnUrl=/app/main/projects/' + calculation.projectId + '/automaxd/' + calculation.id;
            }
            else if (calculation.calculationType === 5) {
                returnUrl = '?returnUrl=/app/main/projects/' + calculation.projectId + '/pdccalcs/' + calculation.id;
            }
            else if (calculation.calculationType === 6) {
                returnUrl = '?returnUrl=/app/main/projects/' + calculation.projectId + '/calcp/' + calculation.id;
            }
            else if (calculation.calculationType === 7) {
                returnUrl = '?returnUrl=/app/main/projects/' + calculation.projectId + '/networkcalc/' + calculation.id;
            }
            else if (calculation.calculationType === 8) {
                returnUrl = '?returnUrl=/app/main/projects/' + calculation.projectId + '/arcflash/' + calculation.id;
            }
            else {
                return;
            }
            // do not append version as it is being appended from routeback component.
            this._router.navigateByUrl('/app/main/routeback' + returnUrl + otherParams);
        }
    }

    OpenProject(project): any {
        if (this.hasPlanExpired) {
            this.handleUnlicencesOrExpiredFlow(this.user, this.calculationStandard, this._companyAccountService, this._router)
            // this.setExpirePlanDetails();
        }
        else {
            var otherParams = '?name=' + project.name + '&v=' + this.appSession.application.version;
            this._router.navigateByUrl('/app/main/projects/' + project.id + '/calcs' + otherParams);
        }
    }

    private collectRouteParams(route: ActivatedRoute): any {
        let currentRoute: ActivatedRoute | null = route;
        const params: any = {}; // Object to store parameters from all children

        while (currentRoute) {
            const currentParams = currentRoute.snapshot.params;
            Object.assign(params, currentParams);

            // Move to the next child route
            currentRoute = currentRoute.firstChild;
        }

        return params;
    }

    checkActiveClass(calculation): boolean {
        let result: boolean = false;
        var url = '';
        var otherParams = '?name=' + calculation.projectName + '&cname=' + calculation.name;
        if (calculation.calculationType === 1) {
            url = '/app/main/projects/' + calculation.projectId + '/calcs/' + calculation.id;
        }
        else if (calculation.calculationType === 3) {
            url = '/app/main/projects/' + calculation.projectId + '/bscalcs/' + calculation.id;
        }
        else if (calculation.calculationType === 4) {
            url = '/app/main/projects/' + calculation.projectId + '/automaxd/' + calculation.id;
        }
        else if (calculation.calculationType === 5) {
            url = '/app/main/projects/' + calculation.projectId + '/pdccalcs/' + calculation.id;
        }
        else if (calculation.calculationType === 6) {
            url = '/app/main/projects/' + calculation.projectId + '/calcp/' + calculation.id;
        }
        else if (calculation.calculationType === 7) {
            url = '/app/main/projects/' + calculation.projectId + '/networkcalc/' + calculation.id;
        }
        else if (calculation.calculationType === 8) {
            url = '/app/main/projects/' + calculation.projectId + '/arcflash/' + calculation.id;
        }
        else {
            return;
        }
        let fullUrl = url + otherParams;
        if (this._router.url == encodeURI(fullUrl))
            result = true;
        return result;
    }

    checkProjectActiveClass(project): boolean {
        let result: boolean = false;
        var url = '/app/main/projects/' + project.id + '/calcs';
        var otherParams = '?name=' + project.name;

        let fullUrl = url + otherParams;
        if (this._router.url == encodeURI(fullUrl))
            result = true;
        return result;
    }

    setExpirePlanDetails(): void {
        this.expiredPlanHeadingText = '';
        this.expiredPlanBodyText = '';
        this.SetExpiredPlanPopText();
        this.ShowExpiryPopUp();
    };

    SetExpiredPlanPopText(): void {
        this.expiredPlanHeadingText = this.premiumUser ? 'Your Plan has Expired' : 'Your Free Trial has Ended';
        this.expiredPlanBodyText = this.premiumUser ? 'We have great value monthly and annual plans' : 'Please purchase a plan';
    };

    ShowExpiryPopUp(): void {
        $('#ListSubscriptionExpiredModel').appendTo("body").modal({
            show: true,
            keyboard: false,
            backdrop: 'static'
        });
    };

    extendTrialPeriod() {
        this.isLoaderForExtendPlan = true;
        this._planService.extendTrialPeriod().subscribe(result => {
            if (result) {
                window.location.reload();
            }
            this.isLoaderForExtendPlan = false;
        }, () => {
            this.isLoaderForExtendPlan = false;
        });
    }

    GoToPricing(): void {
        if (this.calculationStandard === 0 || this.calculationStandard === 1)
            this._router.navigate(['/app/main/projects/', 'subscriptionplans']);
        else if (this.calculationStandard === 2)
            this._router.navigate(['/app/main/projects/', 'bscalcsubscriptionplans']);
        $('#team-model').modal('hide');
    };

    SetUpgradeLink() {
        //        
        if (this.isPlanAddedManually === true) {
            if (this.calculationStandard === 0 || this.calculationStandard === 1)
                this._router.navigate(['/app/main/projects/', 'subscriptionplans']);
            else if (this.calculationStandard === 2)
                this._router.navigate(['/app/main/projects/', 'bscalcsubscriptionplans']);
        }
        else
            this.ManageSubscription(this.user, this.loaderService, this._subscriptionService);
    }
}

import { AfterViewInit, Component, Injector, OnInit, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { AppConsts } from '@shared/AppConsts';
import { SubscriptionStartType } from '@shared/AppEnums';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import * as moment from 'moment';
import { AppComponentBase } from 'shared/common/app-component-base';
import { SettingService } from 'services/project.service';
import { SignalRHelper } from 'shared/helpers/SignalRHelper';
import { CommonLookupServiceProxy } from '@shared/service-proxies/service-proxies';




@Component({
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less']
})
export class AppComponent extends AppComponentBase implements OnInit, AfterViewInit {

    private viewContainerRef: ViewContainerRef;
    private router: Router;

    subscriptionStartType = SubscriptionStartType;
    installationMode = true;

    public constructor(
        injector: Injector,
        viewContainerRef: ViewContainerRef,
        private _router: Router,
        private _appSessionService: AppSessionService,
        private _settingService: SettingService,
        private _commonLookupServiceProxy: CommonLookupServiceProxy) {
        super(injector);

        this.viewContainerRef = viewContainerRef; // You need this small hack in order to catch application root view container ref (required by ng2 bootstrap modal)
        this.router = _router;
        this.router.events.subscribe((val) => {
            this._settingService.hidenavbar();
        })
    }

    ngOnInit(): void {
        this.checkApiStatus();
        setInterval(() => {
            this.checkApiStatus();
        }, 60000); // Check every 60 seconds
        this.installationMode = UrlHelper.isInstallUrl(location.href);

        if (this.appSession.application) {
            SignalRHelper.initSignalR(() => {
                var chatHub = null;
                abp.log.level = abp.log.levels.ERROR;
                abp.signalr.connect();
                abp.signalr.startConnection(abp.appPath + 'signalr', connection => {
                    chatHub = connection; // Save a reference to the hub

                    connection.on('getMessage', message => { // Register for incoming messages
                        if (message == "Role_Updated") {
                            window.location.reload();
                        }
                        else if (message == "User_Added") {
                            if (this._router.url.indexOf('/app/main/manage-account') > -1) {
                                window.location.reload();
                            }
                        }
                        else if (message == "User_Updated") {
                            if (this._router.url.indexOf('/app/main/manage-account') > -1) {
                                window.location.reload();
                            }
                        }

                    });

                    connection.onclose(e => {
                        if (e) {
                            // abp.log.debug('Chat connection closed with error: ' + e);
                        }
                        else {
                            // abp.log.debug('Caht disconnected');
                        }

                        if (!abp.signalr.autoConnect) {
                            return;
                        }

                        setTimeout(() => {
                            connection.start().then(result => {
                            });
                        }, 1000);
                    });

                }).then(function (connection) {
                    // abp.log.debug('Connected to myChatHub server!');
                });
            });
        }

    }

    checkApiStatus() {
        this._commonLookupServiceProxy.ping().subscribe();
    }

    subscriptionStatusBarVisible(): boolean {
        return this._appSessionService.tenantId > 0 &&
            (this._appSessionService.tenant.isInTrialPeriod ||
                this.subscriptionIsExpiringSoon());
    }

    subscriptionIsExpiringSoon(): boolean {
        if (this._appSessionService.tenant.subscriptionEndDateUtc) {
            return moment().utc().add(AppConsts.subscriptionExpireNootifyDayCount, 'days') >= moment(this._appSessionService.tenant.subscriptionEndDateUtc);
        }

        return false;
    }
    // hidenavbar()
    // {
    //     if($("#m_ver_menu ul>li").length==0)
    //         {
    //             $(".m-brand__tools").css('display','none');
    //             $("#m_aside_left").css('display','none');
    //             $("#DivAdmin").addClass("nonadmincls");
    //         }
    //         else
    //         {
    //             $(".m-brand__tools").css('display','table-cell');
    //             $("#m_aside_left").css('display','block');
    //             $("#DivAdmin").removeClass("nonadmincls");
    //         }

    // }
    ngAfterViewInit(): void {
        if (mApp.initialized) {
            return;
        }



        if ($("#m_ver_menu ul>li").length == 0) {
            // $(".m-brand__tools").css('display','none');
            $("#m_aside_left_hide_toggle").css('visibility', 'hidden');
            $("#m_aside_left_offcanvas_toggle").css('visibility', 'hidden');
            $("#m_aside_left").css('display', 'none');
            $("#DivAdmin").addClass("nonadmincls");
        }
        else {
            $(".m-brand__tools").css('display', 'table-cell');
            $("#m_aside_left").css('display', 'block');
            $("#DivAdmin").removeClass("nonadmincls");
        }


        this._settingService.hidenavbar();

        mApp.init();
        mLayout.init();
        mApp.initialized = true;
    }
}

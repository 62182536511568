import { Component, Injector, OnInit, AfterViewInit } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';
@Component({
    selector: 'app-anonymoususer.checkout.cancel',
    templateUrl: './anonymoususer.checkout.cancel.component.html',
    animations: [appModuleAnimation()]
})
export class AnonymousUserCheckOutCancelComponent extends AppComponentBase implements OnInit, AfterViewInit {
    constructor(injector: Injector, private _authService: AppAuthService) {
        super(injector);
    }
    ngOnInit(): void {
    }
    ngAfterViewInit() {
        $('#aContinueToHomepage').on('click', (e) => {
            this._authService.logout();
        });
    }
}
